<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00065 12.6673C7.43932 12.6673 6.87732 12.5487 6.33132 12.3373L8.00065 12.6673ZM8.00065 12.6673C10.3307 12.6673 12.6607 10.6453 13.922 8.31265C14.0267 8.11865 14.0267 7.88332 13.922 7.68932C13.59 7.07532 13.1807 6.48732 12.72 5.94798M3.33398 12.6673L12.6673 3.33398M6.51598 9.48532C5.69598 8.66532 5.69598 7.33532 6.51598 6.51532C7.33598 5.69532 8.66598 5.69532 9.48598 6.51532"
      stroke="#80869A"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.3625 4.63798C10.3312 3.83998 9.16517 3.33398 7.99983 3.33398C5.66983 3.33398 3.33983 5.35598 2.0785 7.68932C1.97383 7.88332 1.97383 8.11865 2.0785 8.31265C2.70917 9.47865 3.6065 10.5667 4.63717 11.364"
      stroke="#80869A"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
