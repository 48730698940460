<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.0785 8.31198C1.97383 8.11798 1.97383 7.88265 2.0785 7.68865C3.33983 5.35598 5.66983 3.33398 7.99983 3.33398C10.3298 3.33398 12.6598 5.35598 13.9212 7.68932C14.0258 7.88332 14.0258 8.11865 13.9212 8.31265C12.6598 10.6453 10.3298 12.6673 7.99983 12.6673C5.66983 12.6673 3.33983 10.6453 2.0785 8.31198V8.31198Z"
      stroke="#6764FF"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.41421 6.58579C10.1953 7.36683 10.1953 8.63316 9.41421 9.41421C8.63316 10.1953 7.36683 10.1953 6.58579 9.41421C5.80474 8.63316 5.80474 7.36683 6.58579 6.58579C7.36683 5.80474 8.63316 5.80474 9.41421 6.58579Z"
      stroke="#6764FF"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
