<template>
  <div class="modal">
    <div
      class="modal__overlay"
      @click="closeEditModal"
    />
    <div class="modal__content">
      <div
        class="modal__close"
        @click="closeEditModal"
      >
        <VIconCrossRed />
      </div>

      <h2 class="modal__title">
        Редактирование блюда
      </h2>

      <div
        v-show="error"
        class="error"
      >
        Не удалось сохранить изменения
      </div>

      <div class="modal__image">
        <label
          for="uploadImg"
          class="image__label"
        >
          <input
            id="uploadImg"
            type="file"
            name="uploadImg"
            class="image__input"
            @change="uploadFile"
          >
          <template v-if="!imageURL">
            <span class="image__preview">
              <VIconLotPlaceholder class="image__preview-icon" />
            </span>
          </template>

          <span
            v-else
            class="image__lot"
          >
            <span
              class="image__delete-icon"
              @click.stop.prevent="pictureModal = true"
            >
              <v-icon-cross-red />
            </span>
            <img
              :src="imageURL"
              alt="Изображение блюда"
              class="image__lot-img"
              @click.stop.prevent="openImg(data)"
            >
          </span>

          <span class="image__text">
            {{ imageURL ? 'Редактировать фото' : 'Загрузить фото' }}
          </span>
        </label>
      </div>

      <div class="modal__input-wrap">
        <vLandingInput
          id="restName"
          :model-value="name"
          placeholder="Название на кассе"
          disabled
        />
      </div>

      <div class="modal__input-wrap">
        <VLimitedInput
          id="ourName"
          v-model="ourName"
          :value="ourName"
          placeholder="Название у гостя"
          max-length="61"
        />
      </div>

      <div class="modal__input-wrap">
        <VLimitedInput
          id="description"
          v-model="description"
          :value="description"
          is-textarea
          textarea-height="148px"
          placeholder="Описание"
          max-length="300"
        />
      </div>

      <div class="modal__input-wrap">
        <VLimitedInput
          id="composition"
          v-model="composition"
          :value="composition"
          is-textarea
          textarea-height="126px"
          placeholder="Состав"
          max-length="255"
        />
      </div>

      <div class="modal__input-wrap modal__field-label">
        Вес блюда:
      </div>

      <div class="modal__row">
        <div class="modal__input-wrap">
          <VLimitedInput
            id="count"
            v-model="count"
            :value="count"
            type="text"
            rule="number"
            :error="hasCountErrors ? 'Не выбрано количество' : ''"
            placeholder="Количество"
            max-length="8"
          />
        </div>

        <div class="modal__input-wrap">
          <vSelect
            :values="itemsTypes"
            :data="type_of_calculus"
            :error="hasCalculusErorrs ? 'Не выбрана единица измерения' : ''"
            element="type"
            placeholder="Ед. измерения"
            @selectValue="handlerTypeOfCalculus"
          />
        </div>
      </div>

      <div class="modal__input-wrap">
        <VLimitedInput
          id="calories"
          v-model="calories"
          :value="calories"
          type="text"
          rule="number"
          placeholder="Калорий в блюде"
          max-length="4"
        />
      </div>

      <div class="modal__input-wrap modal__field-label">
        Метки блюда:
      </div>

      <div class="modal__input-wrap">
        <vMultiSelect
          :values="itemsMarks"
          :data="mark"
          element="type"
          :placeholder="mark.length ? '' : 'Метка не выбрана'"
          @selectValue="handlerMarks"
        />
      </div>

      <div class="modal__row">
        <div class="modal__btn">
          <vButton
            theme="gray"
            @click="closeEditModal"
          >
            Отменить
          </vButton>
        </div>

        <div class="modal__btn">
          <vButton
            :disabled="!hasChanges || disabledButton"
            @click="save"
          >
            Сохранить
          </vButton>
        </div>
      </div>
    </div>

    <template v-if="confirmationModal">
      <div class="modal__confirmation">
        <div
          class="modal__overlay"
          @click="closeConfirmationModal"
        />

        <div class="modal__content">
          <h2 class="modal__title">
            Предупреждение
          </h2>

          <div class="modal__confirm-text">
            Вы действительно хотите закрыть окно без сохранения изменений?
          </div>

          <div class="modal__row">
            <div class="modal__btn">
              <vButton
                theme="gray"
                @click="closeConfirmationModal"
              >
                Нет
              </vButton>
            </div>

            <div class="modal__btn">
              <vButton @click="closeEditModal">
                Да
              </vButton>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="pictureModal">
      <div class="modal__confirmation">
        <div
          class="modal__overlay"
          @click="pictureModal"
        />

        <div class="modal__content">
          <h2 class="modal__title">
            Предупреждение
          </h2>

          <div class="modal__confirm-text">
            Вы действительно хотите удалить фото блюда?
          </div>

          <div class="modal__row">
            <div class="modal__btn">
              <vButton @click="deleteImage">
                Удалить
              </vButton>
            </div>

            <div class="modal__btn">
              <vButton
                theme="gray"
                @click="closePictureModal"
              >
                Отменить
              </vButton>
            </div>
          </div>
        </div>
      </div>
    </template>
    <VLightPreloader
      v-show="loading"
      :fixed="false"
      :transparent="false"
    />
  </div>
</template>

<script>
import vButton from '@/components/v-button'
import VIconLotPlaceholder from '@/assets/images/icons/v-icon-lot-placeholder'
import VIconCrossRed from '@/assets/images/icons/v-icon-cross-red'
import VLandingInput from '@/components/v-input'
import VLimitedInput from '@/components/v-limited-input'
import VSelect from '@/components/v-select'
import VLightPreloader from '@/components/v-light-preloader'
import { mapActions, mapGetters } from 'vuex'
import vMultiSelect from '../v-multi-select.vue'
import { isEqual } from 'lodash'
export default {
  name: 'VAdminMenuLotEditModal',
  components: {
    VSelect,
    VLimitedInput,
    VLandingInput,
    VIconCrossRed,
    VIconLotPlaceholder,
    vButton,
    VLightPreloader,
    vMultiSelect
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  emits: ['selectCatgoryEdit', 'save', 'close', 'showPictureModal'],
  data() {
    return {
      loading: false,
      image: null,
      imageURL: '',
      name: '',
      ourName: '',
      description: '',
      composition: '',
      count: '0',
      type_of_calculus: '',
      calories: '',
      deletedImage: false,
      error: false,

      itemsTypes: [
        { id: 'пусто', name: 'Пусто' },
        { id: 'гр', name: 'Грамм' },
        { id: 'кг', name: 'Килограмм' },
        { id: 'мг', name: 'Миллиграмм' },
        { id: 'мл', name: 'Миллилитр' },
        { id: 'см', name: 'См' },
        { id: 'л', name: 'Литр' },
        { id: 'шт', name: 'Штук' },
        { id: 'порция', name: 'Порция' },
      ],
      itemsMarks: {},

      confirmationModal: false,
      pictureModal: false,
      storage: [],
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    disabledButton() {
      return this.hasCountErrors || this.hasCalculusErorrs
    },
    hasCountErrors() {
      return !!(
        !this.count &&
        this.type_of_calculus !== 'Пусто' &&
        this.type_of_calculus
      )
    },
    hasCalculusErorrs() {
      return !!(
        (this.count && this.type_of_calculus === 'Пусто') ||
        !this.type_of_calculus
      )
    },
    hasChanges() {
      const productImg = this.data.product_picture

      if (
        productImg?.versions?.optimized &&
        this.imageURL !== productImg.versions.optimized
      ) {
        return true
      }
      if (!productImg && this.imageURL) {
        return true
      }
      if (
        this.data.internal_name !== '-' &&
        this.data.internal_name !== null &&
        this.data.internal_name !== this.ourName
      ) {
        return true
      }
      if (this.data.internal_name === null && this.ourName) {
        return true
      }
      if (
        (this.data?.internal_description &&
          this.data.internal_description !== this.description) ||
        (!this.data?.internal_description && this.description)
      ) {
        return true
      }
      if (
        (this.data?.ingredients &&
          this.data.ingredients !== this.composition) ||
        (!this.data?.ingredients && this.composition)
      ) {
        return true
      }
      if (
        (this.data?.count && String(this.data.count) !== this.count) ||
        (!this.data?.count && this.count)
      ) {
        return true
      }
      if (
        (this.data?.calories &&
          String(this.data.calories) !== this.calories) ||
        (!this.data?.calories && this.calories)
      ) {
        return true
      }

      return this.checkEditMark || this.checkEditCalculus
    },
    checkEditCalculus() {
      if (this.data?.unit) {
        const value = this.data?.unit

        if (this.type_of_calculus !== 'Грамм' && value === 'гр') return true
        if (this.type_of_calculus !== 'Килограмм' && value === 'кг') return true
        if (this.type_of_calculus !== 'Миллиграмм' && value === 'мг')
          return true
        if (this.type_of_calculus !== 'Миллилитр' && value === 'мл') return true
        if (this.type_of_calculus !== 'См' && value === 'см') return true
        if (this.type_of_calculus !== 'Литр' && value === 'л') return true
        if (this.type_of_calculus !== 'Штук' && value === 'шт') return true
        if (this.type_of_calculus !== 'Порция' && value === 'порция')
          return true
      } else if (this.type_of_calculus && this.type_of_calculus !== 'Пусто') {
        return true
      }

      return false
    },
    checkEditMark () {
      return !isEqual(this.itemsMarks, this.data.labels)
    },
    mark () {
      const arr = []
      for (const key in this.itemsMarks) {
        if (Object.prototype.hasOwnProperty.call(this.itemsMarks, key)) {
          this.itemsMarks[key].status && arr.push(this.itemsMarks[key].name)
        }
      }
      return arr
    },

    checkedMarks () {
      let count = 0
      for (const key in this.itemsMarks) {
        if (Object.hasOwnProperty.call(this.itemsMarks, key)) {
          this.itemsMarks[key].status && count++
        }
      }

      return count
    }
  },
  mounted() {
    this.name = this.data.name || ''
    this.ourName =
      this.data.internal_name !== '-' &&
      this.data.internal_name !== null &&
      this.data.internal_name !== 'null'
        ? this.data.internal_name
        : ''
    this.description = this.data?.internal_description || ''
    this.composition = this.data?.ingredients || ''
    this.count = this.data?.count
      ? String(this.data?.count)
      : ''
    this.calories = this.data?.calories
      ? String(this.data?.calories)
      : ''

    if (this.data.product_picture)
      this.imageURL = this.data.product_picture.versions.optimized || this.data.product_picture.versions.original

    if (this.data?.unit) {
      switch (this.data.unit) {
        case 'гр':
          this.type_of_calculus = 'Грамм'
          break
        case 'кг':
          this.type_of_calculus = 'Килограмм'
          break
        case 'мг':
          this.type_of_calculus = 'Миллиграмм'
          break
        case 'мл':
          this.type_of_calculus = 'Миллилитр'
          break
        case 'см':
          this.type_of_calculus = 'См'
          break
        case 'л':
          this.type_of_calculus = 'Литр'
          break
        case 'шт':
          this.type_of_calculus = 'Штук'
          break
        case 'порция':
          this.type_of_calculus = 'Порция'
          break
      }
    } else {
      this.type_of_calculus = 'Пусто'
    }
    if (this.data?.labels) {
      this.itemsMarks = JSON.parse(JSON.stringify(this.data.labels))
    }
  },
  methods: {
    ...mapActions('menuAdmin', ['PRODUCT_UPDATE']),
    closeEditModal(onSave = true) {
      if (this.hasChanges && !this.confirmationModal && onSave) {
        this.confirmationModal = true
      } else {
        this.image = null
        this.imageURL = ''
        this.name = ''
        this.ourName = ''
        this.description = ''
        this.composition = ''
        this.count = '0'
        this.type_of_calculus = 'Пусто'
        this.calories = ''
        this.mark = []
        this.deletedImage = false

        this.loading = false
        this.confirmationModal = false
        this.pictureModal = false

        this.$emit('close', null)
      }
    },
    closeConfirmationModal() {
      this.confirmationModal = false
    },
    closePictureModal() {
      this.pictureModal = false
    },
    async save() {
      this.error = false
      if (this.loading) return

      let unit = ''
      if (this.type_of_calculus) {
        let selectedType = this.itemsTypes.find(
          (e) => e.name === this.type_of_calculus
        ).id
        unit = selectedType === 'пусто' ? '' : selectedType
      }

      const deletedImage = this.deletedImage && !this.image

      const payload = {
        token: this.GET_AUTHENTIFICATED,
        id: this.data.id,
        internal_name: this.ourName,
        product_picture: this.image || '',
        delete_picture: deletedImage,
        internal_description: this.description,
        ingredients: this.composition,
        count: +this.count,
        unit: unit,
        calories: parseInt(this.calories),
        labels: {
          spicy: this.itemsMarks.spicy.status,
          vegan: this.itemsMarks.vegan.status,
          dietary: this.itemsMarks.dietary.status,
          gluten_free: this.itemsMarks.gluten_free.status,
          hit: this.itemsMarks.hit.status,
          child: this.itemsMarks.child.status
        },
      }

      try {
        this.loading = true
        const picture = await this.PRODUCT_UPDATE(payload)
        delete payload.token
        delete payload.delete_picture
        payload.labels = this.itemsMarks

        this.$emit('save', { ...this.data, ...payload, picture: picture})
        this.closeEditModal(false)
      } catch (error) {
        this.error = true
        console.error('VAdminMenuLotEditModal__ERROR', error)
      } finally {
        this.loading = false
      }
    },
    uploadFile(event) {
      this.image = event.target.files[0]
      this.imageURL = URL.createObjectURL(this.image)
    },
    deleteImage() {
      if (this.data.product_picture) {
        this.deletedImage = true
      }

      this.image = null
      this.imageURL = null

      this.pictureModal = false
    },
    handlerTypeOfCalculus(payload) {
      this.type_of_calculus = payload.name
    },
    handlerMarks(payload) {
      if (this.itemsMarks[payload.key].status) {
        this.itemsMarks[payload.key].status = false
      } else if (this.checkedMarks < 2) {
        this.itemsMarks[payload.key].status = true
      }
    },
    openImg(data) {
      if(this.imageURL) {
        this.storage = null
        this.storage = [data, this.imageURL]
        this.$emit('showPictureModal', this.storage)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &__overlay {
    position: absolute;
    left: 0;
    background: rgba(220, 222, 227, 0.9);
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  &__content {
    position: relative;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    z-index: 2;
    padding: 12px 12px 0 12px;
    border: 1px solid #e6e7eb;
    border-radius: 18px;
    max-width: 686px;
    background: #fff;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 6px;
    border: 1px solid #edeef1;
    border-radius: 100%;
    background: #f7f8fa;
    cursor: pointer;
  }

  &__title {
    text-align: center;
    margin-bottom: 16px;
  }

  &__input-wrap {
    margin-bottom: 16px;
    :deep(.vLimitedInput) {
      margin-bottom: 0;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;

    .modal__input-wrap,
    .modal__btn {
      width: calc(50% - 8px);
    }
  }

  &__field-label {
    font-size: 16px;
    color: #80869a;
  }

  &__btn {
    position: relative;
    &.disabled {
      opacity: 0.2;
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  &__image {
    margin-bottom: 16px;
  }

  &__confirmation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

  &__confirm-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin: 24px 0;
    text-align: center;
    font-size: 17px;
    line-height: 22px;
    color: #010d35;
  }
}

.image {
  &__label {
    display: block;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
  }

  &__input {
    position: absolute;
    width: 0;
    left: 0;
    opacity: 0;
  }

  &__preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin: 0 auto 8px;
    border: 1px solid #e6e7eb;
    border-radius: 10px;
    background-color: #f7f8fa;
  }

  &__preview-icon {
    width: 42px;
    height: 42px;
  }

  &__lot {
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto 8px;
    border-radius: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  &__delete-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 6px;
    border: 1px solid #edeef1;
    border-radius: 100%;
    background: #f7f8fa;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    display: block;
    width: 100%;
    color: #6764ff;
    text-align: center;
  }
}
.error {
  text-align: center;
  margin-bottom: 20px;
  color: red;
  font-size: 14px;
}
</style>
