<template>
  <div class="vAdminMenuHelp">
    <div class="vAdminMenuHelp__wrapper">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="vAdminMenuHelp__ico"
      >
        <path
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke="#6764FF"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.68555 9.68509C9.92247 8.66396 10.8525 7.95595 11.8999 7.99942C13.072 7.9345 14.0769 8.82772 14.1499 9.99942C14.1499 11.5033 11.9999 11.9994 11.9999 12.9994M12.1249 15.7494C12.1249 15.8185 12.0689 15.8744 11.9999 15.8744C11.9308 15.8744 11.8749 15.8185 11.8749 15.7494C11.8749 15.6804 11.9308 15.6244 11.9999 15.6244"
          stroke="#6764FF"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 15.625V15.625C12.069 15.625 12.125 15.681 12.125 15.75"
          stroke="#6764FF"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div class="vAdminMenuHelp__description">
        Наведите сюда, чтобы узнать – Как работает редактирование категорий и
        сортировка?
      </div>
    </div>
    <ul class="vAdminMenuHelp__tooltip">
      <li class="vAdminMenuHelp__tooltip-item">
        - Зажмите и тащите за
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.66602 8.5H12.3327"
            stroke="#80869A"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.66602 11.168H12.3327"
            stroke="#80869A"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.66602 5.83398H12.3327"
            stroke="#80869A"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        , чтобы сортировать список и менять местами очерёдность категорий.
      </li>
      <li class="vAdminMenuHelp__tooltip-item">
        -Чтобы отсортировать список активных и не активных категорий для гостя
        заведения, нажмите “Показать активные для гостя
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.33398 10.834V6.16732C3.33398 4.87815 4.37815 3.83398 5.66732 3.83398H10.334C11.6232 3.83398 12.6673 4.87815 12.6673 6.16732V10.834C12.6673 12.1232 11.6232 13.1673 10.334 13.1673H5.66732C4.37815 13.1673 3.33398 12.1232 3.33398 10.834Z"
            stroke="#DCDEE3"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        ” над категориями.
      </li>
      <li class="vAdminMenuHelp__tooltip-item">
        - Чтобы изменить название категории, нажмите
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.72067 11.8333H3.33333C2.59667 11.8333 2 11.2367 2 10.5V3.83333C2 3.09667 2.59667 2.5 3.33333 2.5H10C10.7367 2.5 11.3333 3.09667 11.3333 3.83333V5.83333"
            stroke="#80869A"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 5.5H8.33333"
            stroke="#80869A"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.66602 8.83333V5.5"
            stroke="#80869A"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.4147 14.2857L14.4513 10.249C14.738 9.96232 14.738 9.49832 14.4513 9.21232L13.288 8.04898C13.0013 7.76232 12.5373 7.76232 12.2513 8.04898L8.21467 12.0857C8.07667 12.2237 8 12.4097 8 12.6037V14.5003H9.89667C10.0907 14.5003 10.2767 14.4237 10.4147 14.2857Z"
            stroke="#80869A"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </li>
      <li class="vAdminMenuHelp__tooltip-item">
        - Чтобы включить категорию в меню при сканировании QR-кода гостем
        заведения, нажмите
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.0785 8.81198C1.97383 8.61798 1.97383 8.38265 2.0785 8.18865C3.33983 5.85598 5.66983 3.83398 7.99983 3.83398C10.3298 3.83398 12.6598 5.85598 13.9212 8.18932C14.0258 8.38332 14.0258 8.61865 13.9212 8.81265C12.6598 11.1453 10.3298 13.1673 7.99983 13.1673C5.66983 13.1673 3.33983 11.1453 2.0785 8.81198V8.81198Z"
            stroke="#80869A"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.41421 7.08579C10.1953 7.86683 10.1953 9.13316 9.41421 9.91421C8.63316 10.6953 7.36683 10.6953 6.58579 9.91421C5.80474 9.13316 5.80474 7.86683 6.58579 7.08579C7.36683 6.30474 8.63316 6.30474 9.41421 7.08579Z"
            stroke="#80869A"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </li>
      <ul class="vAdminMenuHelp__tooltip-inner">
        <li class="vAdminMenuHelp__tooltip-item-inner">
          Название у посетителя – отображается в меню при сканировании QR-кода
          гостем
        </li>
        <li class="vAdminMenuHelp__tooltip-item-inner">
          Название на кассе – подгружается автоматически из учётной системы
          ресторана
        </li>
      </ul>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.vAdminMenuHelp {
  position: relative;
  z-index: 1;
  cursor: help;
  border-bottom: 1px solid #edeef1;
  padding-bottom: 8px;
  &__wrapper {
    display: flex;
    align-items: center;
    &:hover + .vAdminMenuHelp__tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
  &__description {
    color: #6764ff;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    width: calc(100% - 24px);
  }
  &__ico {
    margin-right: 9px;
  }
  &__tooltip {
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: calc(100% + 2px);
    margin: 0;
    left: -15px;
    width: calc(100% + 40px);
    background: #fff;
    font-size: 12px;
    color: #80869a;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), 0px 6px 24px rgba(0, 0, 0, 0.16);
    &::before {
      content: '';
      width: 15px;
      height: 15px;
      background: #fff;
      position: absolute;
      top: -8px;
      left: 18px;
      transform: rotate(45deg);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1),
        0px 6px 24px rgba(0, 0, 0, 0.16);
      z-index: 1;
    }
    &::after {
      content: '';
      width: 30px;
      height: 12px;
      background: #fff;
      position: absolute;
      top: 0px;
      left: 10px;
      z-index: 2;
    }
    &-inner {
      margin: 0;
    }
    svg {
      margin-bottom: -5px;
    }
  }
  &__tooltip-item {
    margin-bottom: 20px;
    &-inner {
      padding-left: 15px;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #80869a;
        position: absolute;
        left: 5px;
        top: 5px;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
